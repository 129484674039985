import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import style from './style.less'
import Button from '@yaak/components/src/Button'
import Icon from '@yaak/components/src/Icon'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  addNewCollection,
  addToCollection,
  Collection,
  Dataset,
  getCollections,
  getDataset,
} from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import WelcomeDialog from '../WelcomeDialog'
import { isSandboxUser } from '@yaak/nutron/src/utils/sandbox'

interface IAddToCollectionsDialogProps {
  isOpen: boolean
  token: string
  selectedIds: string[]
  onCancel: (event?: any) => void
  onCollectionSave?: (event?: any) => void
  datasetId?: string
  isNewCollection?: boolean
  datasets?: Dataset[]
}

const AddToCollectionsDialog = ({
  isOpen,
  token,
  datasets,
  selectedIds,
  isNewCollection,
  datasetId,
  onCancel,
  onCollectionSave,
}: IAddToCollectionsDialogProps) => {
  const [isNew, setIsNew] = useState(isNewCollection || false)

  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [datasetsList, setDatasetsList] = useState<Dataset[]>([])
  const [collection, setCollection] = useState<Collection | null>()
  const [collections, setCollections] = useState<Collection[]>([])
  const [openRegisterDialog, setOpenRegisterDialog] = useState<boolean>(false)
  const [newFormValue, setNewFormValue] = useState({ name: '', datasetId })

  useEffect(() => {
    const getDatasetInfo = async (token?: string) => {
      if (datasetId) {
        const dataset = await getDataset({
          token,
          id: datasetId,
          onAlert: setShowToast,
        })
        if (dataset) setDatasetsList([dataset])
      }
    }

    getDatasetInfo(token)
  }, [token, datasetId])

  useEffect(() => {
    if (datasets) setDatasetsList(datasets)
  }, [datasets])

  const onClose = () => {
    setIsNew(false)
    onCancel()
  }

  const getCollectionsList = async () => {
    if (datasetId) {
      const collectionsData = await getCollections({
        token,
        datasetIds: [datasetId],
        onAlert: setShowToast,
      })
      setCollections(collectionsData.data)
    }
  }
  useEffect(() => {
    if (token && datasetId) getCollectionsList()
  }, [token, datasetId])

  const onSubmit = async () => {
    if (isSandboxUser()) {
      setOpenRegisterDialog(true)
      onClose()
      return
    }
    const items = selectedIds.map((id) => {
      const [sessionId, startOffset, endOffset, type] = id.split('&')
      return {
        sessionId,
        startOffset: parseInt(startOffset),
        endOffset: parseInt(endOffset),
        type,
      }
    })
    if (isNew) {
      const data = {
        datasetId: newFormValue.datasetId,
        name: newFormValue.name,
        items,
      }
      const res = await addNewCollection({ token, data, onAlert: setShowToast })
      if (res) {
        setShowToast({
          text: 'Data successfully added to collection',
          type: ToastTypes.info,
        })
      }
      onClose()
      onCollectionSave?.()
    } else {
      const res = await addToCollection({
        collectionId: collection?.id || '',
        token,
        data: { items },
        onAlert: setShowToast,
      })
      if (res) {
        setShowToast({
          text: 'Data successfully added to collection',
          type: ToastTypes.info,
        })
      }
      onClose()
    }
  }

  const hasErrors = isNew
    ? !newFormValue.name || !newFormValue.datasetId
    : !collection

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={onClose}
        open={isOpen}
      >
        <div>
          <DialogTitle>
            <Typography
              type={TypographyTypes.title}
              size={TypographySizes.large}
            >
              {isNew ? 'New collection' : 'Add to collection'}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <div className={style.description}>
              <Typography type={TypographyTypes.body}>
                {isNew
                  ? 'Choose a memorable name and a parent dataset.'
                  : 'Sessions and scenarios can be added to collections.'}
              </Typography>
            </div>
            {isNew ? (
              <div className={style.form}>
                <div className={style.formItem}>
                  <Typography
                    type={TypographyTypes.label}
                    color="color-neutral-040"
                  >
                    Name*
                  </Typography>
                  <TextField
                    placeholder="e.g. High-speed lane changes on highways"
                    value={newFormValue.name}
                    onChange={(e) =>
                      setNewFormValue((v) => ({ ...v, name: e.target.value }))
                    }
                  />
                </div>
                <div className={style.formItem}>
                  <Typography
                    type={TypographyTypes.label}
                    color="color-neutral-040"
                  >
                    Dataset*
                  </Typography>
                  <Select
                    value={newFormValue.datasetId}
                    disabled={!isNewCollection && !!datasetId}
                    onChange={(e) =>
                      setNewFormValue((v) => ({
                        ...v,
                        datasetId: e.target.value,
                      }))
                    }
                  >
                    {datasetsList.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={style.formItem}>
                  <Typography
                    type={TypographyTypes.label}
                    color="color-neutral-040"
                  >
                    Training
                  </Typography>
                  <div className={style.checkbox}>
                    <input type="checkbox" disabled />
                    <Typography
                      type={TypographyTypes.label}
                      color="color-neutral-075"
                    >
                      Train with LeRobot (coming soon)
                    </Typography>
                  </div>
                  <div className={style.checkbox}>
                    <input type="checkbox" disabled />
                    <Typography
                      type={TypographyTypes.label}
                      color="color-neutral-075"
                    >
                      Train with rbyte (coming soon)
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              <Autocomplete
                sx={{ width: '100%' }}
                options={[
                  { id: 'new', name: 'New collection' },
                  ...collections,
                ]}
                disableCloseOnSelect
                value={collection}
                onChange={(e, value) => setCollection(value as Collection)}
                getOptionLabel={(option) => option.name}
                filterOptions={([newCollection, ...option], { inputValue }) => {
                  const filtered = option.filter(({ name }) =>
                    name.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  return [newCollection, ...filtered]
                }}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props
                  return (
                    <li
                      key={key}
                      {...optionProps}
                      className={classNames(
                        optionProps.className,
                        style.option,
                        option.id === 'new' ? style.optionFirst : undefined
                      )}
                      onClick={
                        option.id === 'new'
                          ? () => setIsNew(true)
                          : optionProps?.onClick
                      }
                    >
                      {option.id === 'new' ? (
                        <Icon name="Add" version={Version.v2} />
                      ) : (
                        <input type="checkbox" checked={selected} />
                      )}
                      {option.name}
                    </li>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search collections"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <Icon
                              name={'Collection'}
                              color="color-neutral-040"
                              version={Version.v2}
                            />
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} text="Cancel" tertiary />
            <Button onClick={onSubmit} text="Save" disabled={hasErrors} />
          </DialogActions>
        </div>
      </Dialog>
      <WelcomeDialog
        header="Join Nutron for free"
        open={openRegisterDialog}
        onClose={() => setOpenRegisterDialog(false)}
      />
    </>
  )
}

export default AddToCollectionsDialog
