import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react'
import style from './style.less'
import Icon from '@yaak/components/src/Icon'
import { Version } from '@yaak/components/src/types'
import GridItemSelect, {
  useGridItemSelectStore,
  WINDOW_TYPES,
} from './GridItemSelect'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { formatTime } from '@yaak/nutron/src/utils/time'
import Badge from '@yaak/components/src/Badge'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import DataStatus from '@yaak/admin/src/components/DataStatus'
import GridViewPlayer from './GridViewPlayer'
import {
  getDatasetSession,
  getSessionInfo,
  Scenario,
  SearchSessionData,
  SessionData,
} from '@yaak/components/services/api/api'
import {
  toDurationPrecise,
  toHoursMinutesSecondsFromSeconds,
} from '@yaak/admin/src/helpers/time'
import { getSessionStatus } from '@yaak/admin/src/helpers/drives'
import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Tooltip from '../Tooltip'
import { toastType, ToastTypes } from '../Toast/Toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Map from '@yaak/nutron/src/components/Map'
import MetadataLoader from '@yaak/nutron/src/components/MetadataLoader'
import { isSameYear, format } from 'date-fns'
import { useMetadataStore } from '@yaak/nutron/src/stores/MetadataStore'
import ProgressBar from '../ProgressBar'
import { usePlayerStore } from '@yaak/nutron/src/stores/PlayerStore'
import { useVideosStore } from '@yaak/nutron/src/stores/VideosStore'
import { getOffset } from '@yaak/nutron/src/utils/player'
import { getSelectionId } from '../DrivesOverview/utils'

// Mapping types similar to admin/src/helpers/drives.tsx#L149
const dataTypes: Record<string, number> = { 10: 2, 33: 2, 67: 2, '-1': 4 }

const GridItem = ({
  session,
  token,
  setShowToast,
  checked,
  onCheck,
}: {
  token: string
  session: SessionData | Scenario | SearchSessionData
  setShowToast: Dispatch<SetStateAction<toastType | null>>
  checked: boolean
  onCheck: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  const { datasetId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const context = searchParams.get('context')
  const sensorTopics = useMemo(
    () => (session as Scenario).cameras?.map(({ name }) => name).sort(),
    [session]
  )

  const sensorTopicUrlMapping: Record<string, string> = useMemo(
    () =>
      (session as Scenario).cameras?.reduce(
        (acc, { name, url }) => ({ ...acc, [name]: url }),
        {}
      ),
    [session]
  )
  const { windows } = useGridItemSelectStore(
    useShallow((state) => ({
      windows: state.windows,
    }))
  )
  const sessionData = session as SessionData
  const sessionScenario = session as Scenario

  const { loadingFinished } = useMetadataStore(
    useShallow((state) => ({
      loadingFinished: state.loadingFinished,
    }))
  )

  const { setBegin, setEnd, setContext } = usePlayerStore(
    useShallow((state) => ({
      setBegin: state.setBegin,
      setEnd: state.setEnd,
      setContext: state.setContext,
    }))
  )

  const { updateSession } = useVideosStore(
    useShallow((state) => ({
      updateSession: state.updateSession,
    }))
  )

  const redirectUrl = useMemo(() => {
    const id = sessionData.id || sessionScenario.sessionId
    const { startOffset, endOffset } = sessionScenario
    const params =
      startOffset !== undefined && endOffset !== undefined
        ? `?begin=${startOffset}&end=${endOffset}&context=${parseInt(
            context || '0'
          )}&offset=${getOffset(startOffset, parseInt(context || '0'))}`
        : ''
    return `/dataset/session-logs/${id}${params}`
  }, [sessionData, sessionScenario, context])

  const onCopyIconClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation()
    const baseUrl = window.location.protocol + '//' + window.location.host
    navigator.clipboard.writeText(`${baseUrl}${redirectUrl}`)
    setShowToast({ text: 'Link copied to clipboard', type: ToastTypes.success })
  }

  const openDetails = () => {
    navigate(redirectUrl)
  }

  const id = useMemo(
    () =>
      sessionData.id || sessionScenario.startOffset
        ? `${sessionScenario.sessionId}-${sessionScenario.startOffset}`
        : sessionScenario.sessionId,
    [sessionData, sessionScenario]
  )

  const videoUrl = sensorTopicUrlMapping?.[windows[id]] || ''

  const dataStatusResult = getSessionStatus({
    metadataStatus: sessionData.metadataStatus,
    fullVideoStatus: sessionData.fullVideoStatus,
    safetyAIStatus: sessionData.safetyAIStatus,
  })
  const showPopup = !!(
    sessionData.metadataStatus ||
    sessionData.fullVideoStatus ||
    sessionData.videoStatus
  )

  useEffect(() => {
    const updateSessionData = async () => {
      if (datasetId) {
        const sessionInfo = await getDatasetSession({
          token,
          sessionId: sessionData.id || sessionScenario.sessionId,
          onAlert: setShowToast,
          id: datasetId,
        })
        updateSession({
          name: sessionInfo.id,
          startTimestamp: sessionInfo.startTimestamp,
          endTimestamp: sessionInfo.endTimestamp,
        })
      } else {
        const sessionInfo = await getSessionInfo({
          token,
          sessionId: sessionData.id || sessionScenario.sessionId,
          onAlert: setShowToast,
        })
        const id =
          sessionData.id || sessionScenario.startOffset
            ? `${sessionScenario.sessionId}-${sessionScenario.startOffset}`
            : sessionScenario.sessionId
        const { startOffset, endOffset, sessionCanonicalName } = sessionScenario
        if (context && startOffset && windows[id] === WINDOW_TYPES.MAP_DATA) {
          const offsetURLStartTimestamp = new Date(
            (new Date(sessionInfo.startTimestamp).getTime() / 1000 +
              getOffset(startOffset, parseInt(context))) *
              1000
          ).toISOString()
          const offsetURLEndTimestamp = new Date(
            new Date(sessionInfo.startTimestamp).getTime() +
              endOffset * 1000 +
              parseInt(context) * 1000
          ).toISOString()
          updateSession({
            name: sessionCanonicalName,
            startTimestamp: sessionInfo.startTimestamp,
            endTimestamp: sessionInfo.endTimestamp,
            offsetURLStartTimestamp,
            offsetURLEndTimestamp,
          })
          setBegin(startOffset)
          setEnd(endOffset)
          setContext(parseInt(context))
        }
      }
    }
    updateSessionData()
  }, [sessionData, sessionScenario, context, windows, token])

  return (
    <div
      key={id}
      className={classNames(
        style.gridItem,
        'gridViewItem',
        checked ? style.gridItemSelected : undefined
      )}
    >
      <div className={style.header}>
        <GridItemSelect sensorTopics={sensorTopics} id={id} />
      </div>
      {windows[id] === WINDOW_TYPES.MAP_DATA ? (
        <div className={style.mapWrapper}>
          {!loadingFinished[
            (session as SessionData).id || (session as Scenario).sessionId
          ] && (
            <MetadataLoader
              sessionId={
                (session as SessionData).id || (session as Scenario).sessionId
              }
            />
          )}
          {!loadingFinished[
            (session as SessionData).id || (session as Scenario).sessionId
          ] ? (
            <ProgressBar />
          ) : (
            <Map
              uuid={`gridItem_${new Date().getTime()}`}
              key={
                (session as SessionData).id || (session as Scenario).sessionId
              }
              token={token}
              id={
                (session as SessionData).id || (session as Scenario).sessionId
              }
            />
          )}
        </div>
      ) : (
        <GridViewPlayer
          id={id}
          token={token}
          url={videoUrl}
          startOffset={sessionScenario.startOffset}
          endOffset={sessionScenario.endOffset}
          scenarios={(session as SearchSessionData).scenarios}
        />
      )}
      <div className={style.content} onClick={openDetails}>
        <div className={style.dateTime}>
          <Typography
            type={TypographyTypes.label}
            size={TypographySizes.large}
            version={Version.v2}
          >
            {isSameYear(new Date(session.startTimestamp), new Date())
              ? format(new Date(session.startTimestamp), 'dd MMM')
              : format(new Date(session.startTimestamp), 'dd MMM y')}
            {', '}
            {formatTime(session.startTimestamp, 0, true)}
          </Typography>
          <Tooltip text={'Copy link'} position={'top'}>
            <Icon
              className={style.copyIcon}
              name="Copy"
              version={Version.v2}
              onClick={onCopyIconClick}
            />
          </Tooltip>
        </div>
        <div className={style.badgeContainer}>
          <Badge
            label={
              sessionData.startTimestamp && sessionData.endTimestamp
                ? toDurationPrecise(
                    sessionData.startTimestamp,
                    sessionData.endTimestamp
                  )
                : toHoursMinutesSecondsFromSeconds(
                    sessionScenario.endOffset - sessionScenario.startOffset
                  )
            }
            type={BadgeType.grey}
            icon={
              <Icon
                name="Timer"
                color="color-neutral-040"
                version={Version.v2}
              />
            }
          />
          {(session as SearchSessionData).scenarios?.length > 0 && (
            <Badge
              label={(session as SearchSessionData).scenarios.length}
              type={BadgeType.blue}
              icon={
                <Icon
                  name="Scenario"
                  color="new-color-blue-036"
                  version={Version.v2}
                />
              }
            />
          )}
        </div>
        <div className={style.footer}>
          <Typography
            type={TypographyTypes.label}
            version={Version.v2}
            color="color-neutral-040"
          >
            {sessionScenario.sessionCanonicalName}
          </Typography>
          {showPopup && dataStatusResult.value !== 1 ? (
            <div className={style.statusContainer}>
              <DataStatus
                data={dataStatusResult.data}
                dsAdmin={false}
                type={dataTypes[dataStatusResult.value] || 0}
                version={Version.v2}
                position="top"
                icon={
                  dataStatusResult.value === -1 ? (
                    <Badge label="Error" type={BadgeType.red} />
                  ) : (
                    <Badge label="Uploading" type={BadgeType.grey} />
                  )
                }
              />
            </div>
          ) : (
            <div className={style.selection}>
              <input
                type="checkbox"
                checked={checked}
                onChange={onCheck}
                onClick={(e) => e.stopPropagation()}
                value={getSelectionId(session)}
              />
              {checked}
              <Typography
                type={TypographyTypes.label}
                version={Version.v2}
                color="color-green-038"
              >
                Select
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(GridItem)
