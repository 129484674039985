// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__option--QLSwh {\n  display: flex;\n  gap: 0.5rem;\n}\n.style__optionFirst--atZNB {\n  border-bottom: 1px solid #b3b3b3;\n}\n.style__option--QLSwh input {\n  accent-color: #2e8456;\n  outline: none;\n}\n.style__description--fpbVJ {\n  margin-bottom: 2rem;\n}\n.style__form--opyCX {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n.style__form--opyCX .style__formItem--RxjP5 {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n.style__form--opyCX .style__formItem--RxjP5 .style__checkbox--FLnqU {\n  display: flex;\n  gap: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./../nutron/src/components/AddToCollectionsDialog/style.less"],"names":[],"mappings":"AAIA;EAEI,aAAA;EACA,WAAA;AAJJ;AAKI;EACE,gCAAA;AAHN;AAFA;EAQM,qBAAA;EACA,aAAA;AAHN;AANA;EAaI,mBAAA;AAJJ;AATA;EAgBI,aAAA;EACA,sBAAA;EACA,SAAA;AAJJ;AAdA;EAoBM,aAAA;EACA,sBAAA;EACA,WAAA;AAHN;AAnBA;EAwBQ,aAAA;EACA,WAAA;AAFR","sourcesContent":["@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/colors';\n@import '@yaak/components/src/shadows';\n\n:local {\n  .option {\n    display: flex;\n    gap: @spacing-50;\n    &First {\n      border-bottom: 1px solid @color-neutral-070;\n    }\n    input {\n      accent-color: @color-green-035;\n      outline: none;\n    }\n  }\n  .description {\n    margin-bottom: @spacing-200;\n  }\n  .form {\n    display: flex;\n    flex-direction: column;\n    gap: @spacing-100;\n    .formItem {\n      display: flex;\n      flex-direction: column;\n      gap: @spacing-50;\n      .checkbox {\n        display: flex;\n        gap: @spacing-50;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "style__option--QLSwh",
	"optionFirst": "style__optionFirst--atZNB",
	"description": "style__description--fpbVJ",
	"form": "style__form--opyCX",
	"formItem": "style__formItem--RxjP5",
	"checkbox": "style__checkbox--FLnqU"
};
export default ___CSS_LOADER_EXPORT___;
