import { create } from 'zustand'
import {
  YAAKMetadata,
  RoboticsMetadata,
  RoboticsXYZ,
  calculateAcceleration,
  TurnSignal,
} from '../utils/protobufParse'
import { ChartXY } from '@lightningchart/lcjs'

export type Setting = {
  color?: string
  display: boolean
  unit?: string
  format?: (value: any) => string | number
}

export type YAAKMetadataKeys =
  | 'time_stamp'
  | 'steering_angle_normalized'
  | 'brake_pedal_normalized'
  | 'gear'
  | 'speed'
  | 'turn_signal'
  | 'hp_loc_latitude'
  | 'hp_loc_longitude'
  | 'hp_loc_altitude'
  | 'heading'
  | 'position_covariance'
  | 'heading_error'
  | 'steering_angle'
  | 'acceleration'
  | 'gas_pedal_normalized'

export type YAAKChartKeys =
  | 'steering_angle_normalized'
  | 'gas_pedal_normalized'
  | 'brake_pedal_normalized'
  | 'speed'
  | 'steering_angle'
  | 'acceleration'

export type RoboticsChartKeys = 'gripper'

export type RoboticsMetadataKeys =
  | 'action/eef_xyz_delta'
  | 'action/roll_pitch_yaw'
  | 'action/gripper_state'
  | 'observation/eef_xyz'
  | 'observation/gripper_state'
  | 'observation/rgb/primary'
  | 'observation/roll_pitch_yaw'

export type YAAKSettings = Record<YAAKMetadataKeys, Setting>
export type RoboticsSettings = Record<RoboticsMetadataKeys, Setting>
export type MetadataSettings = YAAKSettings | RoboticsSettings
export type YAAKChartSettings = Record<YAAKChartKeys, Setting>
export type RoboticsChartSettings = Record<RoboticsChartKeys, Setting>
export type ChartSettings = YAAKChartSettings | RoboticsChartSettings

const formatXYZData = (data: RoboticsXYZ) =>
  `x=${data.x || '0'}, y=${data.y || '0'}, z=${data.z || '0'}`

const convertPositionCovariance = (positionCovariance: number[]) =>
  positionCovariance.map((pc) => pc / 100)

export const ROBOTICS_METADATA_SETTINGS: MetadataSettings = {
  'action/eef_xyz_delta': {
    display: true,
    unit: 'meters',
    format: formatXYZData,
  },
  'action/gripper_state': {
    display: true,
    format: formatXYZData,
  },
  'action/roll_pitch_yaw': {
    display: true,
    unit: 'meters',
    format: formatXYZData,
  },
  'observation/eef_xyz': {
    display: true,
    unit: 'meters',
    format: formatXYZData,
  },
  'observation/gripper_state': {
    display: true,
    format: (data) => data.value || 0,
  },
  'observation/rgb/primary': {
    display: true,
    format: (data) => data?.timestamp?.seconds,
  },
  'observation/roll_pitch_yaw': {
    display: true,
    unit: 'meters',
    format: formatXYZData,
  },
}

const toFixedValue = (value: number, fixed: number) =>
  parseFloat((value || 0).toFixed(fixed))

export const YAAK_METADATA_SETTINGS: MetadataSettings = {
  time_stamp: {
    display: true,
    format: (timeStamp) => new Date(timeStamp.seconds).getTime(),
  },
  acceleration: {
    display: true,
    format: (value) => parseFloat(calculateAcceleration(value).toFixed(3)),
    unit: 'm/s²',
  },
  brake_pedal_normalized: {
    display: true,
    format: (value) => toFixedValue(value.brake_pedal_normalized, 3),
  },
  gas_pedal_normalized: {
    display: true,
  },
  gear: {
    display: true,
  },
  heading: {
    display: true,
    format: (value) => toFixedValue(value.heading, 6),
    unit: 'deg',
  },
  heading_error: {
    display: true,
    format: (value) => toFixedValue(value.heading_error, 6),
    unit: 'deg',
  },
  hp_loc_altitude: {
    display: true,
    format: (value) => toFixedValue(value.hp_loc_altitude, 6),
  },
  hp_loc_latitude: {
    display: true,
    format: (value) => toFixedValue(value.hp_loc_latitude, 6),
  },
  hp_loc_longitude: {
    display: true,
    format: (value) => toFixedValue(value.hp_loc_longitude, 6),
  },
  position_covariance: {
    display: true,
    format: (value) => `[${convertPositionCovariance(value).join(', ')}]`,
    unit: 'cm²',
  },
  speed: {
    display: true,
    format: (value) => toFixedValue(value.speed, 3),
    unit: 'km/h',
  },
  steering_angle: {
    display: true,
    format: (value) => toFixedValue(value.steering_angle, 3),
    unit: 'deg',
  },
  steering_angle_normalized: {
    display: true,
    format: (value) => toFixedValue(value.steering_angle_normalized, 3),
  },
  turn_signal: {
    display: true,
    format: (value) => TurnSignal[value.turn_signal],
  },
}

export const ROBOTICS_CHART_SETTINGS: RoboticsChartSettings = {
  gripper: {
    color: LESS_COLORS['color-pink-074'],
    display: true,
  },
}

export const YAAK_CHART_SETTINGS: YAAKChartSettings = {
  acceleration: {
    color: LESS_COLORS['color-pink-074'],
    display: true,
  },
  brake_pedal_normalized: {
    color: LESS_COLORS['color-lime-036'],
    display: true,
  },
  gas_pedal_normalized: {
    color: LESS_COLORS['color-lime-062'],
    display: true,
  },
  speed: {
    color: LESS_COLORS['new-color-yellow-059'],
    display: true,
  },
  steering_angle: {
    color: LESS_COLORS['new-color-purple-069'],
    display: true,
  },
  steering_angle_normalized: {
    color: LESS_COLORS['new-color-purple-039'],
    display: true,
  },
}

export type MapSettingsKeys = 'route_tags' | 'annotations'

export type MapSettings = Record<MapSettingsKeys, Setting>

const DEFAULT_MAP_SETTINGS: MapSettings = {
  route_tags: {
    color: LESS_COLORS['color-neutral-cool-060'],
    display: true,
  },
  annotations: {
    color: LESS_COLORS['new-color-red-066'],
    display: true,
  },
}

interface MetadataStoreProps {
  chartSettings: ChartSettings
  metadataSettings: MetadataSettings
  mapSettings: MapSettings
  chart: ChartXY | null
  url: string
  type: string
  metadata: Record<string, YAAKMetadata>
  seconds: number[]
  loadingFinished: any
  secondsIndex: number
}

interface MetadataStoreState {
  chartSettings: ChartSettings
  metadataSettings: MetadataSettings
  mapSettings: MapSettings
  chart: ChartXY | null
  url: string
  type: string
  seconds: number[]
  secondsIndex: number
  metadata: Record<string, YAAKMetadata>
  loadingFinished: any
  updateUrl: (url: string) => void
  updateChart: (chart: ChartXY) => void
  updateMetadata: (sessionId: string, metadata: YAAKMetadata) => void
  updateSeconds: (seconds: number[]) => void
  updateSecondsIndex: (secondsIndex: number) => void
  updateMetadataSettings: (settings: MetadataSettings) => void
  updateChartSettings: (settings: ChartSettings) => void
  updateMapSettings: (mapSettings: MapSettings) => void
  updateType: (type: string) => void
  updateMetadataLoadingFinished: (id: string, loadingFinished: boolean) => void
  reset: () => void
}

const initialState: MetadataStoreProps = {
  chartSettings: YAAK_CHART_SETTINGS,
  metadataSettings: YAAK_METADATA_SETTINGS,
  mapSettings: DEFAULT_MAP_SETTINGS,
  loadingFinished: {},
  chart: null,
  url: '',
  metadata: {},
  seconds: [],
  secondsIndex: 0,
  type: '',
}

export const useMetadataStore = create<MetadataStoreState>((set) => ({
  chartSettings: YAAK_CHART_SETTINGS,
  metadataSettings: YAAK_METADATA_SETTINGS,
  mapSettings: DEFAULT_MAP_SETTINGS,
  chart: null,
  url: '',
  type: '',
  loadingFinished: {},
  metadata: {} as Record<string, YAAKMetadata | RoboticsMetadata>,
  seconds: [],
  secondsIndex: 0,
  updateUrl: (url) =>
    set(() => ({
      url,
    })),
  updateType: (type) =>
    set(() => ({
      type,
    })),
  updateMetadata: (sessionId, metadata) => {
    return set((state) => ({
      ...state,
      metadata: {
        ...state.metadata,
        [sessionId]: {
          ...metadata,
        },
      },
    }))
  },
  updateSeconds: (seconds) =>
    set(() => ({
      seconds,
    })),
  updateSecondsIndex: (secondsIndex) =>
    set(() => ({
      secondsIndex,
    })),
  updateChart: (chart: ChartXY) =>
    set(() => ({
      chart,
    })),
  updateMetadataSettings: (metadataSettings) =>
    set(() => ({
      metadataSettings,
    })),
  updateChartSettings: (chartSettings) =>
    set(() => ({
      chartSettings,
    })),
  updateMapSettings: (mapSettings) =>
    set(() => ({
      mapSettings,
    })),
  updateMetadataLoadingFinished: (id, loadingFinished) =>
    set((state) => ({
      loadingFinished: {
        ...state.loadingFinished,
        [id]: loadingFinished,
      },
    })),
  reset: () => {
    set(initialState)
  },
}))
