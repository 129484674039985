import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import style from './style.less'
import { GeoFeature } from '../../stores/VideosStore'
import mapboxgl from 'mapbox-gl'
import { usePlayerStore } from '../../stores/PlayerStore'
import config from '@yaak/components/services/api/config'
import { useMetadataStore } from '../../stores/MetadataStore'
import {
  CurriculumLineString,
  CurriculumPoint,
  Gnss,
  Location,
  YAAKMetadata,
  SafetyScore,
  Way,
  YAAK_SCHEMA_NAME_MAPPING,
} from '../../utils/protobufParse'
import {
  Event,
  getEvents,
  getIncidentOutcomes,
  IncidentOutcome,
  IncidentOutcomes,
} from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { useShallow } from 'zustand/react/shallow'
import { ROAD_TYPE, SURFACE } from '../../utils/osm'
import { useParams, useSearchParams } from 'react-router-dom'
import nearestPoint from '@turf/nearest-point'
import { featureCollection, point } from '@turf/turf'
import marker from '@yaak/components/assets/images/marker.png'
import incident from '@yaak/components/assets/images/incident.png'
import { getOffset } from '@yaak/nutron/src/utils/player'

mapboxgl.accessToken = config.mapbox.accessToken

const CURRICULUM_ITEM_TYPE: Record<number, string> = {
  0: 'RIGHT_BEFORE_LEFT',
  1: 'GIVE_WAY',
  2: 'PEDESTRIAN_CROSSING',
  3: 'BUS_STOP',
  4: 'TRAIN_CROSSING',
  5: 'TRAFFIC_CALMER',
  6: 'LOWERED_KERB',
  7: 'RIGHT_TURN_ON_RED',
  8: 'UNKNOWN',
}

const CURRICULUM_LINE_TYPE: Record<number, string> = {
  0: 'UNPROTECTED_LEFT',
  1: 'LEFT',
  2: 'UNPROTECTED_RIGHT_WITH_BIKE',
  3: 'PROTECTED_RIGHT_WITH_BIKE',
  4: 'PROTECTED_LEFT',
  5: 'MULTILANE_LEFT',
  6: 'MULTILANE_RIGHT',
  7: 'ROUNDABOUT',
  8: 'LIMITED_ACCESS_WAY',
  9: 'LIVING_STREET',
  10: 'LOW_SPEED_REGION',
  11: 'ONE_WAY',
  12: 'TRAM_TRACKS',
  13: 'PRIORITY_FORWARD_BACKWARD',
  14: 'HILL_DRIVE',
  15: 'ROAD_NARROWS',
  16: 'ENTERING_MOVING_TRAFFIC',
  17: 'MERGE_IN_OUT_ON_HIGHWAY',
  18: 'PRIORITY_WAY',
  19: 'TUNNEL',
  20: 'WAY',
  21: 'UNKNOWN',
  22: 'PARKING',
  23: 'LANE_CHANGE',
}

const LAYER_IDS = {
  INCIDENTS: 'incidents',
  ROUTE_TAGS: 'route_tags',
  ROUTE_LINES: 'route_lines',
}

const findScore = (scores: SafetyScore[], seconds: number) =>
  scores.filter((s) => s.clip.end_timestamp.seconds === seconds)[0]

const removeLayer = (map: any, layerId: string, mapId?: string) => {
  const id = `${layerId}_${mapId}`
  if (map.getLayer(id) !== undefined) {
    map.removeLayer(id)
  }
  if (map.getSource(id)) {
    map.removeSource(id)
  }
}

interface addRouteProps {
  id: string
  map: mapboxgl.Map | null
  route: any
  lineColor: string
  mapId: string
}

const addRoute = ({ id, map, route, lineColor, mapId }: addRouteProps) => {
  if (map && route) {
    removeLayer(map, `${id}_${mapId}`)

    map.addSource(`${id}_${mapId}`, {
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          coordinates: route.geometry.coordinates,
          type: 'LineString',
        },
      },
      type: 'geojson',
    })

    map.addLayer({
      id: `${id}_${mapId}`,
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-color': lineColor,
        'line-opacity': 0.75,
        'line-width': 5,
      },
      source: `${id}_${mapId}`,
      type: 'line',
    })
    zoomToGeoSource({ map, feature: route.geometry })
  }
}

interface zoomToGeoSourceProps {
  map: mapboxgl.Map
  feature: any
}

const zoomToGeoSource = ({ map, feature }: zoomToGeoSourceProps) => {
  const coordinates = [
    feature.coordinates[0],
    feature.coordinates[feature.coordinates.length - 1],
  ]

  const bounds = coordinates.reduce((bounds, coord) => {
    return bounds.extend(coord)
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]))

  !bounds.isEmpty() && map.fitBounds(bounds, { padding: 100 })
}

interface findIncidentDataProps {
  data?: Way[]
  item: Gnss
}

const findIncidentData = ({ data = [], item }: findIncidentDataProps) =>
  data.filter(
    (d) =>
      item.time_stamp.seconds >= d.start.seconds &&
      item.time_stamp.seconds <= d.end.seconds
  )[0]

interface findMapFeatureDataProps {
  data?: Way[]
  item: CurriculumPoint
}

const findMapFeatureData = ({ data = [], item }: findMapFeatureDataProps) => {
  return data.filter((d) => {
    return d.start.seconds === item.timestamp.seconds
  })[0]
}

interface findMapFeatureLineDataProps {
  data?: Way[]
  item: CurriculumLineString
}

const findMapFeatureLineData = ({
  data = [],
  item,
}: findMapFeatureLineDataProps) => {
  return data.filter((d) => {
    return (
      d.start.seconds >= item.start.seconds && d.end.seconds <= item.end.seconds
    )
  })[0]
}

const findIncidentByEvent = (event: Event, gnss: Gnss[] = []) => {
  return gnss.filter((g) => {
    return (
      Math.floor(new Date(event.startTimestamp).getTime() / 1000) ===
      g.time_stamp.seconds
    )
  })[0]
}

const findIncidentLabel = (
  event?: Event,
  incidentsOutcomes?: IncidentOutcome[]
) =>
  incidentsOutcomes?.filter(
    (incidentOutcome) => incidentOutcome.id === event?.tag
  )[0]?.slug

const updatePopup = (wayPoint: Way, popupText: string) => {
  if (wayPoint) {
    popupText += wayPoint.lanes ? `<p>lanes ${wayPoint.lanes}</p>` : ''
    popupText += wayPoint.maxspeed
      ? `<p>max speed ${wayPoint.maxspeed}</p>`
      : ''
    popupText += wayPoint.surface
      ? ` <p>road surface ${SURFACE[wayPoint.surface]}</p>`
      : ''
    popupText += `<p>road type ${ROAD_TYPE[wayPoint.highway]}</p>`
  }

  return popupText
}

const findGnssIndex = (e: mapboxgl.MapMouseEvent, gnss: Gnss[]) => {
  const coords = point([e.lngLat.lng, e.lngLat.lat])
  const gnssPoints = featureCollection(
    gnss.map((g) => point([g.longitude, g.latitude]))
  )
  const nearest = nearestPoint(coords, gnssPoints)
  return nearest.properties.featureIndex
}

const removePopups = () => {
  const popups = document.getElementsByClassName('mapboxgl-popup')
  for (const popup of popups) {
    popup.remove()
  }
}

const addLayer = (
  map: mapboxgl.Map,
  layerId: string,
  data: any,
  iconImage: string,
  mapId: string
) => {
  const id = `${layerId}_${mapId}`
  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: data,
    },
  })
  map.addLayer({
    id,
    type: 'symbol',
    source: id,
    layout: {
      'icon-image': iconImage,
      'text-field': ['get', 'title'],
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-offset': [0, 1.25],
      'text-anchor': 'top',
    },
  })
}

const addLayerEvents = (map: mapboxgl.Map, layerId: string, mapId: string) => {
  const id = `${layerId}_${mapId}`
  map.on('mouseenter', id, (e) => {
    removePopups()

    new mapboxgl.Popup({
      offset: 25,
      className: style.mapPopup,
      closeButton: false,
      closeOnMove: true,
    })
      .setLngLat(e.lngLat)
      .setHTML(e.features?.[0].properties?.description)
      .addTo(map)
  })

  map.on('mouseleave', id, () => {
    removePopups()
  })

  map.on('click', id, (e) => {
    removePopups()

    new mapboxgl.Popup({
      offset: 25,
      className: style.mapPopup,
      closeButton: false,
      closeOnMove: true,
    })
      .setLngLat(e.lngLat)
      .setHTML(e.features?.[0].properties?.descriptionClick)
      .addTo(map)
  })
}

const addMapMarkersIncidentsLayer = (
  map: mapboxgl.Map,
  mapId: string,
  metadata: YAAKMetadata,
  incidents?: Gnss[],
  incidentsOutcomes?: IncidentOutcomes,
  events?: Event[]
) => {
  map.loadImage(incident, (error, image: any) => {
    if (error) throw error
    if (!map.hasImage('custom-marker-incident')) {
      map.addImage('custom-marker-incident', image)
    }

    const data = incidents?.map((point, i) => {
      const description = `<p>annotation ${findIncidentLabel(
        events?.[i],
        incidentsOutcomes?.data
      )}</p>`

      let descriptionClick = description

      const wayPoint = findIncidentData({
        data: metadata[YAAK_SCHEMA_NAME_MAPPING.way] || [],
        item: point as Gnss,
      })
      descriptionClick = updatePopup(wayPoint, descriptionClick)

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
        properties: {
          description,
          descriptionClick,
        },
      }
    })

    addLayer(map, LAYER_IDS.INCIDENTS, data, 'custom-marker-incident', mapId)
    addLayerEvents(map, LAYER_IDS.INCIDENTS, mapId)
  })
}

const addMapMarkersLayer = (
  map: mapboxgl.Map,
  metadata: YAAKMetadata,
  mapId: string
) => {
  map.loadImage(marker, (error, image: any) => {
    if (error) {
      throw error
    }
    if (!map.hasImage('custom-marker')) {
      map.addImage('custom-marker', image)
    }

    const data = metadata[YAAK_SCHEMA_NAME_MAPPING.curriculumPoint]?.map(
      (point) => {
        const description = `<p>route tag ${
          CURRICULUM_ITEM_TYPE[(point as CurriculumPoint).type || 0]
        }</p>`
        let descriptionClick = description

        const wayPoint = findMapFeatureData({
          data: metadata[YAAK_SCHEMA_NAME_MAPPING.way] || [],
          item: point,
        })
        descriptionClick = updatePopup(wayPoint, descriptionClick)

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [point.location.longitude, point.location.latitude],
          },
          properties: {
            description,
            descriptionClick,
          },
        }
      }
    )

    addLayer(map, LAYER_IDS.ROUTE_TAGS, data, 'custom-marker', mapId)
    addLayerEvents(map, LAYER_IDS.ROUTE_TAGS, mapId)
  })
}

const addMapLineMarkersLayer = (
  map: mapboxgl.Map,
  metadata: YAAKMetadata,
  mapId: string
) => {
  const data = metadata[YAAK_SCHEMA_NAME_MAPPING.curriculumLineString]?.map(
    (line) => {
      const description = `<p>route tag ${
        CURRICULUM_LINE_TYPE[line.type || 0]
      }</p>`
      let descriptionClick = description

      const wayPoint = findMapFeatureLineData({
        data: metadata[YAAK_SCHEMA_NAME_MAPPING.way] || [],
        item: line,
      })
      descriptionClick = updatePopup(wayPoint, descriptionClick)
      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: line.locations.map((location: Location) => [
            location.longitude,
            location.latitude,
          ]),
        },
        properties: {
          description,
          descriptionClick,
        },
      }
    }
  )

  const id = `${LAYER_IDS.ROUTE_LINES}_${mapId}`

  map.addSource(id, {
    type: 'geojson',
    lineMetrics: true,
    data: {
      type: 'FeatureCollection',
      features: data as any,
    },
  })

  map.addLayer({
    id,
    type: 'line',
    source: id,
    layout: {
      'line-join': 'round',
    },
    paint: {
      'line-color': '#009e9e',
      'line-width': 15,
    },
  })
  addLayerEvents(map, LAYER_IDS.ROUTE_LINES, id)
}

interface MapProps {
  id?: string
  token: string
  uuid: string
}

const Map: React.FunctionComponent<MapProps> = ({ token, id, uuid }) => {
  const { sessionId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [map, setMap] = useState<mapboxgl.Map | null>(null)
  const [route, setRoute] = useState<GeoFeature>()
  const [resolvedId, setResolvedId] = useState<string | undefined>('')
  const [incidents, setIncidents] = useState<Gnss[]>()
  const [incidentsOutcomes, setIncidentsOutcomes] = useState<IncidentOutcomes>()
  const [events, setEvents] = useState<Event[]>()
  const mapContainer = useRef<HTMLDivElement>(null)
  const [point, setPoint] = useState<any>()
  const [hoverPoint, setHoverPoint] = useState<any>()
  const [coordinates, setCoordinates] = useState<number[]>()
  const [heading, setHeading] = useState<number>(0)
  const { begin, context, offset, update, hoverSync } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      offset: state.offset,
      context: state.context,
      update: state.update,
      hoverSync: state.hoverSync,
    }))
  )
  const { metadata, mapSettings, seconds, loadingFinished } = useMetadataStore(
    useShallow((state) => ({
      mapSettings: state.mapSettings,
      seconds: state.seconds,
      metadata: state.metadata,
      loadingFinished: state.loadingFinished,
    }))
  )

  const data = useMemo(() => {
    if (resolvedId) {
      return metadata[resolvedId]
    }
  }, [metadata, resolvedId])

  useEffect(() => {
    setResolvedId(id || sessionId)
  }, [id, sessionId])

  const initialOffset = getOffset(begin, context)

  useEffect(() => {
    const fetchData = async () => {
      const gnss = data?.[YAAK_SCHEMA_NAME_MAPPING.gnss] || []
      if (resolvedId && data && gnss) {
        const events = await getEvents({
          token,
          sessionId: resolvedId,
          onAlert: setShowToast,
        })
        setEvents(events?.data)
        const incidents = events.data.map((event) =>
          findIncidentByEvent(event, gnss)
        )
        setIncidents(incidents)
      }
    }
    token && resolvedId && loadingFinished && fetchData()
  }, [resolvedId, token, data, loadingFinished])

  useEffect(() => {
    const fetchIncidentOutcomes = async () => {
      const incidentsOutcomes = await getIncidentOutcomes({
        token,
        onAlert: setShowToast,
      })
      setIncidentsOutcomes(incidentsOutcomes)
    }

    token && fetchIncidentOutcomes()
  }, [token])

  useEffect(() => {
    const gnss = data?.[YAAK_SCHEMA_NAME_MAPPING.gnss]
    if (gnss && gnss.length > 0 && resolvedId && loadingFinished[resolvedId]) {
      const coordinates = gnss
        .filter(
          (g) =>
            g.latitude !== 0 && g.longitude !== 0 && g.latitude && g.longitude
        )
        .map((g) => [g.longitude?.toFixed(6), g.latitude?.toFixed(6)])
      const route: any = {
        geometry: { type: 'MultiLineString', coordinates },
        type: 'Feature',
      }
      setRoute(route)
    }
  }, [data, resolvedId])

  useEffect(() => {
    if (mapContainer.current && route && resolvedId) {
      const newMap = new mapboxgl.Map({
        center: route.geometry.coordinates[0],
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/light-v9',
        zoom: 5,
      })

      newMap.on('load', () => {
        setMap(newMap)
        newMap.resize()
      })

      return () => {
        newMap.remove()
      }
    }
  }, [route, resolvedId])

  useEffect(() => {
    if (map && resolvedId) {
      addRoute({
        id: 'route',
        map,
        route,
        lineColor: '#000',
        mapId: resolvedId,
      })
    }
  }, [map, route, resolvedId])

  useEffect(() => {
    const gnss = data?.[YAAK_SCHEMA_NAME_MAPPING.gnss]
    if (map && resolvedId && gnss) {
      const value = gnss?.filter((g) => {
        const startTime = gnss[0].time_stamp.seconds + context
        const endTime = gnss[gnss.length - 1].time_stamp.seconds - context
        return (
          g.time_stamp.seconds >= startTime && g.time_stamp.seconds <= endTime
        )
      })
      if (value && value.length > 0) {
        const coordinates = value?.map((g) => [
          g.longitude.toFixed(6),
          g.latitude.toFixed(6),
        ])
        const scenarioRoute: any = {
          geometry: { type: 'MultiLineString', coordinates },
          type: 'Feature',
        }
        addRoute({
          id: `scenarios`,
          map,
          route: scenarioRoute,
          lineColor: LESS_COLORS['new-color-blue-070'],
          mapId: `${resolvedId}_${uuid}`,
        })
      }
    }
  }, [map, route, context, data, resolvedId])

  useEffect(() => {
    const gnss = data?.[YAAK_SCHEMA_NAME_MAPPING.gnss]
    if (map && gnss && route && route.geometry.coordinates.length) {
      if (!point) {
        if (route.geometry.coordinates[0][0] && gnss.length > 0) {
          const el = document.createElement('div')
          const point: any = new mapboxgl.Marker(el)
            .setLngLat([0, 0])
            .addTo(map)
          point.addClassName(style.marker)
          setPoint(point)
        }
      }

      if (!hoverPoint) {
        const el = document.createElement('div')
        const hP: any = new mapboxgl.Marker(el).setLngLat([0, 0]).addTo(map)
        hP.addClassName(style.hoverMarker)
        hP.addClassName(style.hoverMarkerHide)
        setHoverPoint(hP)
      }
    }
  }, [map, route, data, point, hoverPoint])

  useEffect(() => {
    if (map && resolvedId && data) {
      const id = `${resolvedId}_${uuid}`
      if (mapSettings.route_tags.display) {
        addMapMarkersLayer(map, data, id)
        addMapLineMarkersLayer(map, data, id)
      } else {
        removeLayer(map, LAYER_IDS.ROUTE_TAGS, id)
        removeLayer(map, LAYER_IDS.ROUTE_LINES, id)
      }
    }
  }, [resolvedId, data, map, mapSettings.route_tags.display])

  useEffect(() => {
    if (map) {
      const id = `${resolvedId}_${uuid}`
      if (data && mapSettings.annotations.display && incidents) {
        addMapMarkersIncidentsLayer(
          map,
          id,
          data,
          incidents,
          incidentsOutcomes,
          events
        )
      } else {
        removeLayer(map, LAYER_IDS.INCIDENTS, id)
      }
    }
  }, [resolvedId, incidents, map, mapSettings, incidentsOutcomes, data, events])

  useEffect(() => {
    const gnss = data?.[YAAK_SCHEMA_NAME_MAPPING.gnss] || []
    if (
      map &&
      gnss &&
      gnss?.length > 0 &&
      seconds &&
      seconds.length > 0 &&
      loadingFinished &&
      !id
    ) {
      map.on('click', (e: mapboxgl.MapMouseEvent) => {
        const index = findGnssIndex(e, gnss)
        if (index !== -1 && gnss[index]) {
          const offset =
            initialOffset +
            gnss[index].time_stamp.seconds -
            gnss[0].time_stamp.seconds
          update({
            jump: offset,
          })
          searchParams.set('offset', offset.toString())
          setSearchParams(searchParams, { replace: true })
        }
      })
    }
  }, [map, data, seconds, loadingFinished, update, id])

  useEffect(() => {
    const gnss = data?.[YAAK_SCHEMA_NAME_MAPPING.gnss] || []
    if (map && gnss && gnss?.length > 0 && !id) {
      map.on('mousemove', (e) => {
        const index = findGnssIndex(e, gnss)
        if (index !== -1 && gnss[index]) {
          update({
            hoverSync:
              gnss[index].time_stamp.seconds - gnss[0].time_stamp.seconds,
          })
        }
      })
      map.on('mouseleave', () => {
        update({
          hoverSync: null,
        })
      })
    }
  }, [map, data, update, id])

  useEffect(() => {
    const gnss = data?.[YAAK_SCHEMA_NAME_MAPPING.gnss]
    if (map && gnss && gnss?.length > 0 && seconds && seconds.length > 0) {
      const time =
        gnss[0].time_stamp.seconds + offset - getOffset(begin, context)
      const index = seconds?.findIndex((s) => s === (time | 0))
      const position = index !== -1 ? gnss[index] : gnss[0]
      if (position) {
        setCoordinates([position.longitude, position.latitude])
        setHeading(position.heading)
      }
    }
  }, [map, data, offset, seconds])

  useEffect(() => {
    if (
      data?.[YAAK_SCHEMA_NAME_MAPPING.gnss] &&
      coordinates &&
      coordinates.length > 0 &&
      point &&
      coordinates[0] &&
      coordinates[1]
    ) {
      const time =
        data[YAAK_SCHEMA_NAME_MAPPING.gnss]?.[0]?.time_stamp.seconds + offset
      const seconds = Math.floor(time)
      const scoreData = data[YAAK_SCHEMA_NAME_MAPPING.safetyScore] || []
      const safetyScore = scoreData && findScore(scoreData, seconds)
      point.setLngLat(coordinates)

      if (safetyScore?.score) {
        const popup = new mapboxgl.Popup({
          offset: 5,
          className: style.scorePopup,
          closeButton: false,
        }).setText(`${Math.floor(safetyScore.score * 100)}%`)
        point.setPopup(popup)
        point.togglePopup()
      } else {
        point.togglePopup()
      }
      point.setRotation(heading)
    }
  }, [point, coordinates, data, heading, offset])

  useEffect(() => {
    const gnss = data?.[YAAK_SCHEMA_NAME_MAPPING.gnss]
    if (gnss && hoverSync) {
      const value = gnss?.filter(
        (g) =>
          g?.time_stamp.seconds ===
          parseInt(
            (
              gnss[0]?.time_stamp.seconds +
              hoverSync -
              getOffset(begin, context)
            ).toFixed(0)
          )
      )[0]
      if (value && value.latitude && value.longitude) {
        hoverPoint?.removeClassName(style.hoverMarkerHide)
        hoverPoint?.setLngLat([value.longitude, value.latitude])
      }
      const time = gnss[0]?.time_stamp.seconds + hoverSync
      const seconds = Math.floor(time)
      const scoreData = data[YAAK_SCHEMA_NAME_MAPPING.safetyScore] || []
      const safetyScore = scoreData && findScore(scoreData, seconds)
      const wayData = data[YAAK_SCHEMA_NAME_MAPPING.way] || []
      const wayPoint = wayData.filter((d) => {
        return d.start.seconds === seconds
      })[0]

      let popupText = ''

      if (safetyScore) {
        popupText = `${Math.floor(safetyScore.score * 100)}%`
      }

      if (wayPoint) {
        popupText = updatePopup(wayPoint, popupText)
      }

      if (popupText) {
        const popup = new mapboxgl.Popup({
          offset: 5,
          className: style.scorePopup,
          closeButton: false,
        }).setHTML(popupText)
        hoverPoint?.setPopup(popup)
        hoverPoint?.togglePopup()
      } else {
        hoverPoint?.togglePopup()
      }
      hoverPoint?.setRotation(value?.heading)
    } else {
      hoverPoint?.addClassName(style.hoverMarkerHide)
      hoverPoint?.togglePopup()
    }
  }, [hoverSync, data, hoverPoint, begin, context])

  return <div key={id} className={style.map} ref={mapContainer} />
}

export default memo(Map)
