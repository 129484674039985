import { TypedMcapRecords } from '@mcap/core/dist/esm/src/types'

const protobuf = require('protobufjs')
const descriptor = require('protobufjs/ext/descriptor/index.js')

interface Channel {
  protoSchema: any
  schemaName: string
  topic: string
}

export interface Timestamp {
  seconds: number
  nanos: number
}

export interface Location {
  altitude: number
  latitude: number
  longitude: number
  timestamp: Timestamp
}

enum NavSatStatus {
  STATUS_FIX = 0,
  STATUS_SBAS_FIX = 1,
  STATUS_GBAS_FIX = 2,
  STATUS_NO_FIX = -1,
}

enum NavService {
  SERVICE_NONE = 0,
  SERVICE_GPS = 1,
  SERVICE_GLOSNASS = 2,
  SERVICE_COMPASS = 4,
  SERVICE_GALILEO = 8,
}

enum NavSatFix {
  COVARIANCE_TYPE_UNKNOWN = 0,
  COVARIANCE_TYPE_APPROXIMATED = 1,
  COVARIANCE_TYPE_DIAGONAL_KNOWN = 2,
  COVARIANCE_TYPE_KNOWN = 3,
}

enum Gear {
  P = 0,
  R = 1,
  N = 2,
  D = 3,
  B = 4,
}

export enum TurnSignal {
  OFF = 0,
  LEFT = 1,
  RIGHT = 2,
}

export interface Gnss {
  altitude: number
  has_highprecision_loc: boolean
  heading: number
  heading_error: number
  hp_loc_altitude: number
  hp_loc_latitude: number
  hp_loc_longitude: number
  latitude: number
  longitude: number
  position_covariance: number[]
  position_covariance_type: NavSatFix
  service: NavService
  session_timestamp: number
  speed: number
  speed_error: number
  status: NavSatStatus
  time_stamp: Timestamp
}

export interface VehicleMotion {
  time_stamp: Timestamp
  session_timestamp: number
  acceleration_x: number
  acceleration_y: number
  acceleration_z: number
  speed: number
  wheel_speed_fr: number
  wheel_speed_fl: number
  wheel_speed_rr: number
  wheel_speed_rl: number
  steering_angle: number
  steering_angle_normalized: number
  gas_pedal_normalized: number
  brake_pedal_normalized: number
  instructor_pedals_used: boolean
  gear: Gear
  speed_dashboard: number
  mileage: number
}

export interface VehicleState {
  time_stamp: Timestamp
  session_timestamp: number
  door_closed_fl: boolean
  door_closed_fr: boolean
  door_closed_rl: boolean
  door_closed_rr: boolean
  driver_seatbelt_on: boolean
  passenger_seatbelt_on: boolean
  headlight_high_beam_on: boolean
  turn_signal: TurnSignal
}

export interface CurriculumPoint {
  location: Location
  timestamp: Timestamp
  type: number
}

export interface CurriculumLineString {
  end: Timestamp
  locations: Location[]
  start: Timestamp
  type: number
}

interface WeatherCondition {
  cloudcover: number
  condition: number
  description: string
  location: Location
  timestamp: Timestamp
  visibility: number
  weather_id: number
}

interface Turn {
  location: Location
  timestamp: Timestamp
  type: number
}

export interface Clip {
  end_timestamp: Timestamp
  start_timestamp: Timestamp
  locations: Location[]
}

export interface Prediction {
  action: number
  action_groundtruth_logprob: number
  action_l1_norm_prediction_and_groundtruth: number
  action_prediction: number
}

export interface SafetyScore {
  clip: Clip
  evaluation: number
  predictions: Prediction[]
  score: number
}

export interface Way {
  end: {
    seconds: number
  }
  highway: number
  length: number
  locations: Location[]
  maxspeed?: number
  start: Timestamp
  surface: number
  lanes?: number
}

interface DriveSessionInfo {
  drive_name: string
  time_stamp: Timestamp
  version: number
}

export type YAAKDataset =
  | 'sensor/driveSessionInfo'
  | 'sensor/Gnss'
  | 'sensor/ImageMetadata'
  | 'sensor/VehicleMotion'
  | 'sensor/VehicleState'
  | 'osm/Way'
  | 'osm/Turn'
  | 'osm/CurriculumPoint'
  | 'osm/CurriculumLineString'
  | 'osm/SafetyScore'
  | 'weather/Condition'

export type RoboticsDataset =
  | 'action/eef_xyz_delta'
  | 'action/gripper_state'
  | 'action/roll_pitch_yaw'
  | 'info/action_space'
  | 'info/citation'
  | 'info/control_frequency'
  | 'info/data_collect_method'
  | 'info/dataset'
  | 'info/depth_cams'
  | 'info/description'
  | 'info/episodes'
  | 'info/file_size_'
  | 'info/full_name'
  | 'info/gripper'
  | 'info/has_camera_calibration'
  | 'info/has_proprioception'
  | 'info/has_suboptimal'
  | 'info/homepage'
  | 'info/language_annotations'
  | 'info/latex_reference'
  | 'info/name'
  | 'info/registered_dataset_name'
  | 'info/rgb_cams'
  | 'info/robot'
  | 'info/robot_morphology'
  | 'info/scene_type'
  | 'info/wrist_cams'
  | 'observation/eef_xyz'
  | 'observation/gripper_state'
  | 'observation/rgb/primary'
  | 'observation/roll_pitch_yaw'

export const YAAK_SCHEMA_NAME_MAPPING: Record<string, YAAKDataset> = {
  driveSessionInfo: 'sensor/driveSessionInfo',
  gnss: 'sensor/Gnss',
  vehicleMotion: 'sensor/VehicleMotion',
  vehicleState: 'sensor/VehicleState',
  safetyScore: 'osm/SafetyScore',
  way: 'osm/Way',
  turn: 'osm/Turn',
  curriculumPoint: 'osm/CurriculumPoint',
  curriculumLineString: 'osm/CurriculumLineString',
  weatherCondition: 'weather/Condition',
}

interface RoboticsInfoName {
  value: string
}

export interface RoboticsObservationRGBPrimary {
  data: Uint8Array
  format: string
  frame_id: string
  timestamp: Timestamp
}

export interface RoboticsObservationGripper {
  value: number
}

export interface RoboticsXYZ {
  x?: number
  y?: number
  z?: number
}

export type RoboticsSchemaNameKeys =
  | 'driveSessionInfo'
  | 'observation/rgb/primary'
  | 'action/eef_xyz_delta'
  | 'action/roll_pitch_yaw'
  | 'action/gripper_state'
  | 'observation/eef_xyz'
  | 'observation/gripper_state'
  | 'observation/roll_pitch_yaw'

export const ROBOTICS_SCHEMA_NAME_MAPPING: Record<
  RoboticsSchemaNameKeys,
  RoboticsDataset
> = {
  driveSessionInfo: 'info/name',
  'observation/rgb/primary': 'observation/rgb/primary',
  'action/eef_xyz_delta': 'action/eef_xyz_delta',
  'action/roll_pitch_yaw': 'action/roll_pitch_yaw',
  'action/gripper_state': 'action/gripper_state',
  'observation/eef_xyz': 'observation/eef_xyz',
  'observation/gripper_state': 'observation/gripper_state',
  'observation/roll_pitch_yaw': 'observation/roll_pitch_yaw',
}

export interface RoboticsMetadata {
  'info/name'?: RoboticsInfoName[]
  'observation/rgb/primary'?: RoboticsObservationRGBPrimary[]
  'action/eef_xyz_delta'?: RoboticsXYZ[]
  'action/roll_pitch_yaw'?: RoboticsXYZ[]
  'action/gripper_state'?: RoboticsXYZ[]
  'observation/eef_xyz'?: RoboticsXYZ[]
  'observation/gripper_state'?: RoboticsObservationGripper[]
  'observation/roll_pitch_yaw'?: RoboticsXYZ[]
}

export interface YAAKMetadata {
  'sensor/driveSessionInfo'?: DriveSessionInfo[]
  'sensor/Gnss'?: Gnss[]
  'sensor/ImageMetadata'?: any[]
  'sensor/VehicleMotion'?: VehicleMotion[]
  'sensor/VehicleState'?: VehicleState[]
  'osm/SafetyScore'?: SafetyScore[]
  'osm/Way'?: Way[]
  'osm/Turn'?: Turn[]
  'osm/CurriculumPoint'?: CurriculumPoint[]
  'osm/CurriculumLineString'?: CurriculumLineString[]
  'weather/Condition'?: WeatherCondition[]
}

const EXCLUDED_TOPICS = ['sensor/ImageMetadata']

export const calculateAcceleration = (vehicleMotion: VehicleMotion) =>
  Math.sqrt(
    Math.pow(vehicleMotion.acceleration_x || 0, 2) +
      Math.pow(vehicleMotion.acceleration_y || 0, 2) +
      Math.pow(vehicleMotion.acceleration_z || 0, 2)
  )

export const parseProtobufSchema = (
  schemaName: string,
  schemaData: Uint8Array
) => {
  const descriptorSet = descriptor.FileDescriptorSet.decode(schemaData)
  const root = protobuf.Root.fromDescriptor(descriptorSet)
  root.resolveAll()

  return root.lookupType(schemaName)
}

export const parseMessages = async (
  messageIterator: AsyncGenerator<TypedMcapRecords['Message'], void, void>,
  channelIdToProtoSchema: Map<number, Channel>
): Promise<YAAKMetadata> => {
  const result: any = {}

  for await (const message of messageIterator) {
    const info = channelIdToProtoSchema.get(message.channelId)

    const topicName = info?.topic
    if (topicName && !EXCLUDED_TOPICS.includes(topicName)) {
      if (!result[topicName]) {
        result[topicName] = []
      }
      result[topicName].push(info?.protoSchema.decode(message.data))
    }
  }

  return result
}
